import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"

// Components
import Seo from "../components/seo"
import Header from "../components/header"
import Hero from "../components/hero"
import FormLanding from "../components/form-landing"
import BodyLanding from "../components/body-landing"
import TopBullets from "./screens/ease-lab-backlog/top-bullets"
import FormContainer from "../components/form-container"

const FORM_ID = process.env.GATSBY_FORM_LANDING_EASE_LAB_BACKLOG

const EaseLabBacklog = () => {
	return (
		<main>
			<Header />
			<Hero title="Ease your lab backlog">
				<StaticImage
					width={1300}
					loading="eager"
					src="../assets/images/ease-lab-backlog/hero.jpg"
					className="restrictImage"
					alt="Doctors in lab"
				/>
			</Hero>
			<FormContainer>
				<TopBullets />
				<FormLanding
					wordpressFormID={FORM_ID}
					pageSlug="/ease-lab-backlog-thank-you/"
				/>
			</FormContainer>
			<BodyLanding landingPage="ease-lab-backlog" />
		</main>
	)
}

export default EaseLabBacklog


export const Head = () => (
	<Seo
	  title="Ease your lab backlog"
	  description=" Increase efficiencies and leverage the flexibility of portable, instrument-free PCR with Visby Medical."
	  recaptcha
	/>
  )
  