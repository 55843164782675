import React from "react"

import TopSection from "../../../components/top-section"

const TopBullets = props => {
	return (
		<TopSection>
			<p className="title" data-aos="fade-up" data-aos-delay="100">Implement point-of-care PCR</p>
			<p className="title" data-aos="fade-up" data-aos-delay="200">Decrease lab tech burnout</p>
			<p className="title" data-aos="fade-up" data-aos-delay="300">Be a part of the antibiotic stewardship solution</p>
			<p data-aos="fade-up" data-aos-delay="200">Studies show that as many as 40% of female patients are lost to care<sup>1</sup>.
				Ease workflow tensions, increase efficiencies and leverage the flexibility of portable, instrument-free PCR with Visby Medical.
				<br /><span className="references">1. <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4074614/" target="_blank" rel="noopener noreferrer">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4074614/</a></span>
			</p>
		</TopSection>
	)
}
export default TopBullets
